import { config } from '../config';

export type Project = {
  title: string;
  description: string[];
  logo: ProjectLogo;
  link: string;
};

export type ProjectLogo = {
  src: string;
  width: string;
  height: string;
}

export const PROJECT_LIST: Project[] = [
  {
    title: 'Versionings',
    description: [
      'Implementation of versioning automation.',
      'Versioning schema: Semantic Versioning',
    ],
    logo: {
      src: `${config.s3.route}/npm-logo.png`,
      width: '100px',
      height: '100%'
    },
    link: 'http://www.versionings.com',
  },
  {
    title: 'Etc. Poem',
    description: [
      'Etc. poetry content, philosophy, consciousness.',
    ],
    logo: {
      src: `${config.s3.route}/etc-logo.png`,
      width: '100px',
      height: '100px'
    },
    link: 'https://www.etcpoem.com',
  },
  // {
  //   title: 'Etc. Sound',
  //   description: [
  //     'Etc. sound content, philosophy, consciousness.',
  //   ],
  //   logo: {
  //     src: `${config.s3.route}/etc-logo-sound.jpeg`,
  //     width: '100px',
  //     height: '100px'
  //   },
  //   link: 'https://www.etcsound.com',
  // },
];
