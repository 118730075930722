import { v1 as uuid } from 'uuid';
import { Maybe } from '../types';
import { Uid } from './common.model';

export type Cv = CvPeriod[];


export type CvPeriod = {
  uid: Uid;
  companyTitle: string;
  companyLink: Maybe<string>;
  companyLogoPath: Maybe<string>;
  period: CvPeriodDate,
  code: string;
  role: string;
  activity: CvPeriodActivity[];

};

export type CvPeriodDate = {
  start: Date;
  end: Maybe<Date>;
};

export type CvPeriodActivity = string;

export const CV_PERIOD_LIST: Cv = [
  {
    companyTitle: 'Armada Labs',
    companyLink: 'https://armadalabs.com/',
    role: 'Team Leader, Solution Architect, Tech Innovator and Thought Leader',
    uid: uuid(),
    code: 'armada-labs-team-leader',
    period: {
      start: new Date(2022, 6, 1),
      end: void 0,
    },
    companyLogoPath: void 0,
    activity: [
      'Led a cross-functional team in an Agile setting, facilitating daily stand-ups and sprint planning sessions to enhance collaboration and ensure project alignment with customer needs.',
      'Designed and implemented serverless architectures using AWS Lambda, API Gateway, and DynamoDB, resulting in a 30% reduction in infrastructure costs and improved scalability for client applications.',
      'Spearheaded the discovery and onboarding process for new customer projects, conducting workshops and requirement-gathering sessions to ensure a comprehensive understanding of client objectives and expectations.',
      'Directed the technical transformation of existing server-side applications by a microservices architecture, leveraging AWS services and modern development practices to enhance performance and maintainability.',
      `Utilised SST & Serverless to streamline the deployment and management of serverless applications, significantly reducing setup time and enabling rapid iteration based on customer's feedback.`,
      'Business domains technical information has been improving while the writing technical approach and the documentation in line with client’s requirements has been created.',
      'Fostered strong relationships with clients throughout project lifecycles, ensuring consistent communication and alignment on goals, which led to a 97% customer satisfaction rate.',
      'Led the transformation of business requirements into innovative technical approaches, leveraging AWS serverless architecture to deliver scalable solutions that addressed client challenges and enhanced operational efficiency.',
    ]
  },
  {
    companyTitle: 'Vention',
    companyLink: 'https://ventionteams.com/',
    role: 'Staff Manager, Team Lead, Tech Lead and Solution Architect',
    uid: uuid(),
    code: 'vention-solution-architect',
    period: {
      start: new Date(2020, 8, 1),
      end: new Date(2022, 5, 1)
    },
    companyLogoPath: void 0,
    activity: [
      `Led a React frontend project that employed Redux for managing state, focusing on elucidating business dependencies and enhancing additional business assurances.`,
      `Spearheaded a React frontend project employing MobX for state management, targeting long-term maintenance and alignment with projected business goals.`,
      `Designed and implemented the frontend application architecture, emphasizing deployment processes, business data state management, and customization of Material UI components.`,
      `Successfully integrated RxJS observables and GraphQL for React state management, enabling support for production-ready projects.`,
      `Integrated GraphQL to customize database interaction approaches, catering to critical business dependencies.`,
      `Managed the development team to foster developer knowledge growth, ensure project adherence, and facilitate relevant customer interactions.`,
      `Incorporated Test-Driven Development (TDD) into the project to ensure strict dependency management for various business data providers.`,
      `Guided projects through the Scrum process, aligning with the digital software store's business logic interpretation to achieve goals.`,
      `Maintained infrastructure components on Amazon Web Services, including Lambda dependencies, Amplify builds, Load Balancers, ECS (Elastic Container Service), EC2 instances, RDS databases, API Gateway, S3 storage, and Route 53 configurations, among others.`,
      `Achieved high-quality code integration across relevant application layers for various projects.`,
      `Provided personalised education and training to the staff in backend, frontend, database, and DevOps roles, aiming to preempt development issues and support company-wide educational initiatives.`,
      `Enhanced technical knowledge in business domains while crafting technical approaches and documentation in accordance with client requirements.`,
    ]
  },
  {
    companyTitle: 'Softeq, Belarus',
    companyLink: 'https://softeq.com/',
    role: 'Team Lead and Tech Lead',
    uid: uuid(),
    code: 'softeq-tech-lead',
    period: {
      start: new Date(2017, 0, 1),
      end: new Date(2020, 5, 1)
    },
    companyLogoPath: void 0,
    activity: [
      `Implemented Agile methodologies, particularly Scrum, to cultivate proficient project teams and enhance team members' expertise, integrating them into internal company processes.`,
      `Directed a complex UI application project in React, employing functional programming principles to establish the infrastructure for machine learning image calculations.`,
      `Managed the maintenance of MobX state management to ensure the fulfilment of business data dependencies.`,
      `Successfully delivered a long-term infrastructure data management portal using Angular as the frontend source provider, emphasising high maintenance and performance standards.`,
      `Employed Redux for maintenance within an Angular application, leveraging the framework's capabilities and RxJS for efficient state management.`,
      `Integrated a GraphQL data provider into project projections, resulting in the creation of a stable, high-performance frontend portal for a third-party application accessible through web browsers.`,
      `Predominantly engaged in project planning, delivery, and leadership for global business projects, overseeing their establishment and ongoing maintenance.`,
    ]
  },
  {
    companyTitle: 'ASW Systems a.s., Czech',
    companyLink: 'https://www.septim.com/',
    role: 'Senior Software Engineer',
    uid: uuid(),
    code: 'asw-systems-senior',
    period: {
      start: new Date(2016, 6, 1),
      end: new Date(2016, 11, 1)
    },
    companyLogoPath: void 0,
    activity: [
      `Incorporated custom React state management utilizing the RxJS observables approach.`,
      `Achieved high-performance delivery of complex Redux state management, with a dependency on RxJS.`,
      `Integrated modern technologies to facilitate intricate user-business interactions within the complex UI.`,
      `Ensured data consistency for complex forms to maintain a clean and organized database instance.`,
      `Prepared easily maintainable textual materials to support both project development and ongoing maintenance.`,
      `Successfully deployed a comprehensive information system in the HORECA segment, specifically tailored for restaurant and POS systems.`,
    ]
  },
  {
    companyTitle: 'Createch Group, Belarus',
    companyLink: 'https://www.linkedin.com/company/createch-group/about/',
    role: 'Senior Software Engineer',
    uid: uuid(),
    code: 'createch-group-senior',
    period: {
      start: new Date(2015, 9, 1),
      end: new Date(2016, 3, 1)
    },
    companyLogoPath: void 0,
    activity: [
      `Developed a comprehensive charting system for business data using React in conjunction with server-side data.`,
      `Enhanced client-server interactions to optimise data exchange performance.`,
      `Managed the delivery and maintenance of internal company products, focusing on both client and server-side aspects, leveraging the capabilities of modern frameworks.`,
      `Conducted investigations and implemented frontend capabilities, delivering complex UI application components and maintaining server-side processes.`,
      `Leveraged business analyst expertise to enhance product performance and advance the entity in question.`,
    ]
  },
  {
    companyTitle: 'Piplos Media, Belarus',
    companyLink: 'https://piplos.media/en',
    role: 'Middle Software Engineer',
    uid: uuid(),
    code: 'piplos-middle',
    period: {
      start: new Date(2014, 8, 1),
      end: new Date(2015, 9, 1)
    },
    companyLogoPath: void 0,
    activity: [
      `Developed a social media portal using the Symfony2 framework, enabling the exchange of social data within the fashion domain.`,
      `Engaged in project discussions with customers and participated in rough estimating processes to provide effective delivery support.`,
      `Designed and delivered a digital selling platform for products, leveraging modern CMS systems to ensure accurate estimation and long-term support.`,
      `Gained experience in outsourcing web social media and CRM systems, with a focus on user behaviour analysis.`,
      `Led a social media project that utilised modern frameworks such as React and Symfony2, overseeing resource management and maintenance.`,
    ],
  },
  {
    companyTitle: 'New Site, Belarus',
    companyLink: 'https://www.newsite.by/',
    role: 'Junior Software Engineer',
    uid: uuid(),
    code: 'new-site-junior',
    period: {
      start: new Date(2012, 10, 1),
      end: new Date(2015, 7, 1)
    },
    companyLogoPath: void 0,
    activity: [
      `Gained practical experience in utilising digital marketing technologies within the context of customer e-commerce enterprises.`,
      `Seamlessly integrated into a large-scale digital community, handling backend, database, and Symfony2 framework components.`,
      `Acquired development experience in building server-side applications, emphasising production-quality characteristics and maintenance.`,
      `Provided support for various Content Management Systems (CMS) by effectively managing source code and adhering to delivery criteria.`,
      `Successfully delivered a complex external management web resource designed to facilitate the execution of marketing data sampling processes.`,
    ],
  },
]
